import React, { useCallback } from 'react';
import styles from './navLink.module.scss';
import { trackCustomEvent } from 'gatsby-plugin-google-analytics';
import { ReactNode } from 'react';
import { defaultLanguage } from '../data/languages.json';

type Props = {
  anchor: string;
  children: ReactNode;
  langKey: string;
};

export const NavLink = ({ anchor, children, langKey }: Props) => {
  const handleClick = useCallback(() => {
    setTimeout(() => {
      document
        .getElementById(anchor)
        ?.scrollIntoView({ behavior: 'smooth', block: 'start' });
      (window as any)?.ga?.(
        'set',
        'page',
        location.pathname + location.search + location.hash
      );
      trackCustomEvent({
        category: 'nav',
        action: 'pageview',
        label: `Nav: ${anchor}`,
      });
    }, 100);
  }, [anchor]);

  return (
    <a
      href={`/${langKey===defaultLanguage ? '' : langKey}#${anchor}`}
      aria-label={`Scroll to ${anchor}`}
      onClick={handleClick}
      className={styles.link}
    >
      {children}
    </a>
  );
};
