import React, { ReactNode, useEffect } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import smoothscroll from 'smoothscroll-polyfill';

import Header from './header';
import './layout.css';
import styles from './layout.module.scss';

type Props = {
  children: ReactNode;
  location: Location;
};

const Layout = ({ children, location }: Props) => {
  useEffect(() => smoothscroll.polyfill(), []);
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);
  useEffect(() => {
    console.log('wtf :',location);
    if (location.hash) {
      setTimeout(() => {
        document
          .getElementById(location.hash.slice(1))
          
          ?.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }, 100);
    }
  }, [location.hash]);

  return (
    <>
      <Header siteTitle={data.site.siteMetadata.title} location={location} />
      <main>{children}</main>
      <footer className={styles.footer}>
        © {new Date().getFullYear()} SimpleSoft
      </footer>
    </>
  );
};

export default Layout;
