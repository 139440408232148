import React, { useEffect, useState } from 'react';
import { Lang } from './lang';
import { Navigation, Anchors } from './navigation';
import styles from './header.module.scss';
import cn from 'classnames';
import { NavLink } from './navLink';
import { getCurrentLangKey } from 'ptz-i18n';
import { languages, defaultLanguage } from '../data/languages.json';

type Props = {
  siteTitle?: string;
  location: Location;
};

const Header = ({ siteTitle = '', location }: Props) => {
  const [scrolled, changeScrolled] = useState(false);
  useEffect(() => {
    const handler = () => {
      changeScrolled(window.scrollY > 50);
    };
    window.addEventListener('scroll', handler);
    return () => window.removeEventListener('scroll', handler);
  }, []);
  const url = location.pathname;
  const langKey = getCurrentLangKey(languages, defaultLanguage, url);

  return (
    <header className={cn(styles.header, { [styles.scrolled]: scrolled })}>
      <div className={styles.container}>
        <NavLink anchor={Anchors.intro} langKey={langKey}>
          <div className={styles.logo} />
        </NavLink>
        <Navigation location={location} />
        <div className={styles.separator} />
        <Lang location={location} />
      </div>
    </header>
  );
};

export default Header;
