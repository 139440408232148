import React from 'react';
import { NavLink } from './navLink';
import styles from './navigation.module.scss';
import { getCurrentLangKey } from 'ptz-i18n';
import { languages, defaultLanguage } from '../data/languages.json';

export enum Anchors {
  intro = 'intro',
  services = 'services',
  principles = 'principles',
  contact = 'contact',
  career = 'career',
}

const pl: { [id in Anchors]: string } = {
  intro: 'O Nas',
  services: 'Usługi',
  principles: 'Nasze zasady',
  contact: 'Kontakt',
  career: 'Kariera'
}

const langsMap: { [lk: string]: { [id in Anchors]: string } } = {
  pl
}

export const Navigation = ({ location }: { location: Location; }) => {
  const url = location.pathname;
  const langKey = getCurrentLangKey(languages, defaultLanguage, url);
  return (
    <nav className={styles.nav}>
      <NavLink anchor={Anchors.services} langKey={langKey}>{langsMap[langKey]?.[Anchors.services] ?? 'Services'}</NavLink>
      <NavLink anchor={Anchors.principles} langKey={langKey}>{langsMap[langKey]?.[Anchors.principles] ??'Our principles'}</NavLink>
      <NavLink anchor={Anchors.contact} langKey={langKey}>{langsMap[langKey]?.[Anchors.contact] ??'Contact'}</NavLink>
      <NavLink anchor={Anchors.career} langKey={langKey}>{langsMap[langKey]?.[Anchors.career] ??'Career'}</NavLink>
    </nav>
  );
};
