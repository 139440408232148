import React from 'react';
import styles from './lang.module.scss';
import { languages, defaultLanguage } from '../data/languages.json';
import { getCurrentLangKey, getLangs, getUrlForLang, LangInfo } from 'ptz-i18n';
import { ReactNode } from 'react';
import cn from 'classnames';
import AniLink from 'gatsby-plugin-transition-link/AniLink';

const Link = ({ lang }: { lang: LangInfo }) => (
  <AniLink
    cover
    bg="#cce"
    duration={0.7}
    to={lang.link}
    className={cn(styles.link, { [styles.selected]: lang.selected })}
  >
    {lang.langKey}
  </AniLink>
);

export const Lang = ({ location }: { location: Location }) => {
  const url = location.pathname;
  const langKey = getCurrentLangKey(languages, defaultLanguage, url);
  const homeLink = `/${langKey}`.replace(`/${defaultLanguage}/`, '/');
  const langsMenu = getLangs(
    languages,
    langKey,
    getUrlForLang(homeLink, url)
  ).map((item: LangInfo) => ({
    ...item,
    link: item.link.replace(`/${defaultLanguage}/`, '/').replace('//', '/'),
  }));

  return (
    <div className={styles.lang}>
      {langsMenu
        .map((l) => <Link key={l.langKey} lang={l} />)
        .reduce(
          (agg: ReactNode[] | null, b) =>
            agg == null ? [b] : [...agg, '/', b],
          null
        )}
    </div>
  );
};
