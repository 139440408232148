import React, { useRef, useEffect } from 'react';
import { ReactNode } from 'react';
import styles from './section.module.scss';
import cn from 'classnames';

export function isInViewport(elem: HTMLDivElement) {
  const distance = elem.getBoundingClientRect();
  return (
    distance.top + distance.height <=
    (window.innerHeight || document.documentElement.clientHeight)
  );
}
type Props = {
  children: ReactNode;
  anchor: string;
  className?: string;
};
export const Section = ({ children, anchor, className }: Props) => {
  // const ref = useRef<HTMLDivElement>(null);
  // useEffect(() => {
  //   const handler = () => {
  //     if (anchor === 'services') {
  //       const distance = ref.current?.getBoundingClientRect();
  //       console.log(
  //         'distance :',
  //         distance,
  //         window.innerHeight,
  //         document.documentElement.clientHeight
  //       );
  //     }
  //     if (ref.current && isInViewport(ref.current)) {
  //       console.log('visible', anchor);
  //     }
  //   };
  //   window.addEventListener('scroll', handler);
  //   return () => {
  //     window.removeEventListener('scroll', handler);
  //   };
  // }, [ref]);
  return (
    <section id={anchor} className={styles.section}>
      <div className={cn(styles.container, className)}>{children}</div>
    </section>
  );
};
